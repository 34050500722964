import { Stack, Typography } from "@mui/material";
import { FC, PropsWithChildren } from "react";

interface Props {
  title?: string;
  totalCount?: number;
}

const Header: FC<PropsWithChildren<Props>> = ({
  children,
  title = "Название раздела",
  totalCount,
}) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="h4">
        {title}
        {totalCount ? `(${totalCount})` : ""}
      </Typography>

      {children}
    </Stack>
  );
};

export default Header;
