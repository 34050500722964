import {
  EditEventWithUsers,
  EventBackend,
  EventFrontend,
  EventsBackendResponseData,
} from "./types";

export const mapperEventsFromBackend = (
  data: EventsBackendResponseData
): EventFrontend => {
  return {
    totalCount: data.totalCount,
    data: data.data.map((event) => ({
      id: event.id,
      name: event.name,
      isActive: event.is_active,
      createdAt: event.created_at,
      updatedAt: event.updated_at,
    })),
  };
};

export const mapperEventFromBackend = (
  data: EventBackend
): EditEventWithUsers => {
  const { id, name, is_active, created_at, updated_at } = data.event;
  return {
    event: {
      id,
      name,
      isActive: is_active,
      createdAt: created_at,
      updatedAt: updated_at,
    },
    users: data.users.map((user) => ({
      id: 11,
      name: user.name,
      phone: user.phone,
      email: user.email,
      isActive: user.is_active,
      tgFirstName: "dvsdv",
      tgUserName: "vsddvsv",
      updated: new Date(),
    })),
  };
};
