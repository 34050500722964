import { Box, Container } from "@mui/material";
import Header from "./Header";
import Form from "./Form";

const CreateEvent = () => {
  return (
    <Container maxWidth="md">
      <Box pt={3}>
        <Header />
      </Box>

      {/* form */}
      <Box pt={3}>
        <Form />
      </Box>
    </Container>
  );
};

export default CreateEvent;
