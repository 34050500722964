import {
  fetchMailingToAllUsers,
  sendMailingByUserId,
} from "@/modules/mailing/api";
import { BaseMailing } from "@/modules/mailing/types";
import { Button } from "@mui/material";
import { useState } from "react";

interface Props {
  mailingData: BaseMailing;
}

export interface BaseSendMailing {
  message: string;
  mailingId: number;
}
interface SendMailing extends BaseSendMailing {
  users: number[];
}

const sendMailing = async ({ users, message }: SendMailing) => {
  let mailingCount = 0;

  const promise = new Promise((resolve, reject) => {
    users.forEach((user) => {
      setTimeout(async () => {
        let resp = await sendMailingByUserId({
          userId: user,
          text: message,
        });

        if (resp) {
          ++mailingCount;

          if (mailingCount === users.length) {
            resolve(true);
          }
        } else {
          reject(false);
        }
      }, 10);
    });
  });

  return promise;
};

const ControlUnit = ({ mailingData }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { id, name } = mailingData;

  console.log("mailingData", mailingData);

  // const users = [287216290, 287216290];

  // const handleSendMailingToSomeUsers = async () => {
  //   setLoading((v) => !v);

  //   let send = await sendMailing({
  //     users,
  //     message: name,
  //   });

  //   if (send) {
  //     alert("Рассылка успешно разослана!");
  //   } else {
  //     alert("Что-то пошло не так... :(");
  //   }

  //   setLoading((v) => !v);
  // };

  const handleSendMailingToAllUsers = async () => {
    setLoading((v) => !v);

    let resp = await fetchMailingToAllUsers({
      message: name,
      mailingId: id,
    });

    if (resp) {
      alert("Рассылка успешно разослана!");
    } else {
      alert("Что-то пошло не так... :(");
    }

    setLoading((v) => !v);
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleSendMailingToAllUsers}
        disabled={loading}
      >
        {loading ? "Осуществляется рассылка" : "Отправить рассылку"}
      </Button>
    </>
  );
};

export default ControlUnit;
