import { axi } from "@/api/axiosInstance";
import { BaseMailing, GetMailingOutput } from "../types";
import {
  CREATE_MAILING_API,
  EDIT_MAILING_API,
  MAILING_API,
  MAILINGS_API,
  SEND_MAILING_TO_ALL_USERS_API,
} from "./conts";
import { mapMailingResponse, mapMailingsResponse } from "./mapper";
import { BaseSendMailing } from "../pages/Mailing/EditMailing/ControlUnit";

interface MailingParams {
  page: number;
  pageSize: number;
}

export const getMailings = async ({
  page = 1,
  pageSize = 10,
}: MailingParams): Promise<GetMailingOutput | null> => {
  let resp;

  try {
    resp = await axi.get(`${MAILINGS_API}`, {
      params: {
        page,
        itemsPerPage: pageSize,
      },
    });
  } catch (error) {
    console.log(error);
  }

  return mapMailingsResponse(resp?.data);
};

export const getMailing = async (id: number): Promise<BaseMailing | null> => {
  try {
    const resp = await axi.get<BaseMailing>(`${MAILING_API}`, {
      params: {
        id,
      },
    });
    const backendData = resp.data;

    return mapMailingResponse(backendData);
  } catch (error) {
    console.log("getEvent error: ", error);

    return null;
  }
};

interface SendMailingByUserId {
  userId: number;
  text: string;
}

interface TelegramResponse {
  ok: boolean;
}

export const sendMailingByUserId = async ({
  userId,
  text,
}: SendMailingByUserId): Promise<TelegramResponse> => {
  let resp;

  try {
    resp = await axi.post(
      "https://api.telegram.org/bot7364958478:AAEzsWh4TYIxUAunWF-RFOHbTIz7AL5vDlI/sendMessage",
      {
        chat_id: userId,
        text,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (error) {
    console.log("sendMailingByUserId error: ", error);
  }

  return resp?.data.ok || false;
};

export const fetchMailingToAllUsers = async ({
  mailingId,
  message,
}: BaseSendMailing) => {
  let resp;

  try {
    resp = await axi.post(SEND_MAILING_TO_ALL_USERS_API, {
      message,
      mailingId,
    });
  } catch (error) {
    console.log("sendMailingByUserId error: ", error);
  }

  return resp;
};

export const createMailing = async (name: string) => {
  let resp;

  try {
    resp = await axi.post(CREATE_MAILING_API, {
      name,
    });
  } catch (error) {
    console.log("createMailing error: ", error);
  }

  return resp;
};

export const editMailing = async (
  id: string,
  name: string
): Promise<boolean> => {
  let resp;

  try {
    resp = await axi.post(EDIT_MAILING_API, {
      id,
      name,
    });
  } catch (error) {
    console.log("editMailing error: ", error);
  }

  return resp?.data ? resp.data : false;
};
