import { TableCell, TableCellProps, Typography } from "@mui/material";
import { FC } from "react";

export const HeaderTableCell: FC<TableCellProps> = ({ children }) => {
  return (
    <TableCell>
      {/* Понадобилось установить lineHeight, чтобы все ячейки в таблице были одной высоты */}
      <Typography variant="body1" sx={{ lineHeight: "20px" }}>
        {children}
      </Typography>
    </TableCell>
  );
};
