import { useParams } from "react-router-dom";
import Header from "../../../../../components/Header";
import { Box, Stack, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { QUERY_KEY__EVENT } from "../../../../../const/queryKeys";
import { getMailing } from "@/modules/mailing/api";
import { BaseMailing } from "@/modules/mailing/types";
import Form from "../CreateMailing/Form";
import ControlUnit from "./ControlUnit";

const EditMailing = () => {
  const { id } = useParams();
  const eventId = Number.parseInt(id || "0", 10);

  const { data, isLoading, error } = useQuery<BaseMailing | null, Error>(
    [QUERY_KEY__EVENT, eventId],
    () => getMailing(eventId)
  );

  if (isLoading) {
    return "Загружаю данные рассылки...";
  }

  if (error) {
    return "Произошла ошибка :(";
  }

  return (
    <Box px={5} pt={2.5} pb={4.5}>
      <Header title={"Редактирование рассылки"} />

      <Box pt={3}>
        <Form mailingToEdit={data || undefined} editId={id} />
      </Box>

      <Stack
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        spacing={2}
        pt={3}
      >
        <Typography variant="h4">Блок управления</Typography>

        {data ? <ControlUnit mailingData={data} /> : ""}
      </Stack>
    </Box>
  );
};

export default EditMailing;
