import {
  Admin,
  Resource,
  EditGuesser,
  ShowGuesser,
  CustomRoutes,
} from "react-admin";
import { Layout } from "./Layout";
import { dataProvider } from "./dataProvider";
import { authProvider } from "./authProvider";
import { BrowserRouter, Route } from "react-router-dom";
import { lazy } from "react";
import QueryClientProvider from "./providers/QueryClientProvider";
import CreateEvent from "./modules/events/pages/Events/CreateEvent";
import {
  ROUTE__CREATE_EVENT,
  ROUTE__CREATE_MAILING,
  ROUTE__EDIT_EVENT,
  ROUTE__EDIT_MAILING,
  ROUTE__EVENTS,
  ROUTE__MAILING,
  ROUTE__SURVEYS,
  ROUTE__USERS,
} from "./const/routes";
import EditEvent from "./modules/events/pages/Events/EditEvent";
import EditMailing from "./modules/mailing/pages/Mailing/EditMailing";
import CreateMailing from "./modules/mailing/pages/Mailing/CreateMailing";
// import Surveys from "./modules/surveys/pages/Surveys";

const Users = lazy(() => import("./modules/users/pages/Users"));
const Events = lazy(() => import("./modules/events/pages/Events"));
const Staff = lazy(() => import("./modules/staff/pages/Staff"));
const Mailing = lazy(() => import("./modules/mailing/pages/Mailing"));
const Surveys = lazy(() => import("./modules/surveys/pages/Surveys"));

export const App = () => (
  <QueryClientProvider>
    <BrowserRouter>
      <Admin
        layout={Layout}
        dataProvider={dataProvider}
        authProvider={authProvider}
        requireAuth
      >
        <Resource
          name="events"
          list={Events}
          edit={EditGuesser}
          show={ShowGuesser}
        />
        <Resource
          name="users"
          list={Users}
          edit={EditGuesser}
          show={ShowGuesser}
        />
        <Resource
          name="staff"
          list={Staff}
          edit={EditGuesser}
          show={ShowGuesser}
        />
        <Resource
          name="mailing"
          list={Mailing}
          edit={EditGuesser}
          show={ShowGuesser}
        />

        <Resource
          name="surveys"
          list={Mailing}
          edit={EditGuesser}
          show={ShowGuesser}
        />

        <CustomRoutes>
          <Route path={ROUTE__USERS} element={<Users />}></Route>
          <Route path={ROUTE__EVENTS} element={<Events />}></Route>
          <Route path={ROUTE__CREATE_EVENT} element={<CreateEvent />}></Route>
          <Route path={ROUTE__EDIT_EVENT} element={<EditEvent />}></Route>

          <Route path={ROUTE__MAILING} element={<Mailing />}></Route>
          <Route path={ROUTE__SURVEYS} element={<Surveys />}></Route>
          <Route
            path={ROUTE__CREATE_MAILING}
            element={<CreateMailing />}
          ></Route>
          <Route path={ROUTE__EDIT_MAILING} element={<EditMailing />}></Route>
        </CustomRoutes>
      </Admin>
    </BrowserRouter>
  </QueryClientProvider>
);
