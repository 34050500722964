import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import { isProduction } from "./utils/env";

if (isProduction) {
  console.log(`version: ${import.meta.env.VITE_APP_VERSION}`);
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
