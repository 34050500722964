import { BaseMailing, GetMailingOutput } from "../types";

export const mapMailingsResponse = async (data: GetMailingOutput) => {
  return {
    totalCount: data?.totalCount || 0,
    data: data?.data || [],
  };
};

export const mapMailingResponse = async (data: BaseMailing) => {
  return data;
};
