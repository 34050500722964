import { BaseUser } from "@/modules/users/types";
import { FC } from "react";
import { JustTable as Table } from "./Table";

interface Props {
  users?: BaseUser[];
}

const UsersList: FC<Props> = ({ users }) => {
  return (
    <div>
      {users && users.length > 1 ? <Table users={users} /> : "список пуст"}
    </div>
  );
};

export default UsersList;
