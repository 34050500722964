import { Button, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { ROUTE__EVENTS } from "../../../../../../const/routes";

const Header = () => {
  const navigate = useNavigate();
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
    >
      <Typography variant="h4">Создание мероприятия</Typography>

      <IconButton
        size="small"
        aria-label="close"
        onClick={() => navigate(ROUTE__EVENTS)}
      >
        <CloseIcon />
      </IconButton>
    </Stack>
  );
};

export default Header;
