import { HeaderTableCell } from "@/components/ui/table/HeaderTableCell";
import { BaseUser } from "@/modules/users/types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { FC } from "react";
import { v4 as uuidv4 } from "uuid";

interface Props {
  users: BaseUser[];
}

export const JustTable: FC<Props> = ({ users }) => {
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650, maxWidth: "100%" }}>
        <TableHead>
          <TableRow>
            {/* <HeaderTableCell>Дата</HeaderTableCell> */}
            <HeaderTableCell>Имя</HeaderTableCell>
            <HeaderTableCell>Почта</HeaderTableCell>
            <HeaderTableCell>Телефон</HeaderTableCell>
            <HeaderTableCell>Прошел на мероприятие</HeaderTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {users.map((user) => {
            const { name, isActive, phone, email } = user;
            return (
              <TableRow
                key={uuidv4()}
                sx={{
                  "&:hover": {
                    backgroundColor: "#ccc",
                  },
                }}
              >
                <TableCell>
                  <Typography>{name}</Typography>
                </TableCell>

                <TableCell>
                  <Typography>{email}</Typography>
                </TableCell>

                <TableCell>
                  <Typography>{phone}</Typography>
                </TableCell>

                <TableCell>
                  <Typography>{isActive ? "не прошел" : "прошел"}</Typography>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
