import { useParams } from "react-router-dom";
import Header from "../../../../../components/Header";
import { Box, Typography } from "@mui/material";
import Form from "../CreateEvent/Form";
import { useQuery } from "react-query";
import { EditEventWithUsers } from "../../../api/types";
import { getEvent } from "../../../api";
import { QUERY_KEY__EVENT } from "../../../../../const/queryKeys";
import UsersList from "./UsersList";

const EditEvent = () => {
  const { id } = useParams();
  const eventId = Number.parseInt(id || "0", 10);

  const { data, isLoading, error } = useQuery<EditEventWithUsers | null, Error>(
    [QUERY_KEY__EVENT, eventId],
    () => getEvent(eventId)
  );

  if (isLoading) {
    return "Загружаю данные мероприятия...";
  }

  if (error) {
    return "Произошла ошибка при получении мероприятия :(";
  }

  const usersLength =
    data?.users && data?.users.length > 1 ? `(${data?.users.length})` : "";

  return (
    <Box px={5} pt={2.5} pb={4.5}>
      <Header title={"Редактирование события"} />

      <Box pt={3}>
        <Form eventToEdit={data?.event || undefined} editEventId={id} />
      </Box>

      <Box pt={5}>
        <Typography variant="h6">
          Список пользователей, записавшихся на мероприятие {usersLength}
        </Typography>

        {/* список */}
        <UsersList users={data?.users} />
      </Box>
    </Box>
  );
};

export default EditEvent;
