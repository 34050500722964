import axios from "axios";
import { apiBaseUrl } from "../helpers/api";

export const axi = axios.create({
  baseURL: apiBaseUrl,
});

axi.interceptors.request.use(function (config) {
  const token = import.meta.env.VITE_API_TOKEN;
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});
