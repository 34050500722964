import { Button, Stack } from "@mui/material";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { useNavigate } from "react-router-dom";
import { FC } from "react";
import { BaseMailing } from "@/modules/mailing/types";
import { ROUTE__MAILING } from "@/const/routes";
import { createMailing, editMailing } from "@/modules/mailing/api";
import { queryClient } from "@/reactQueryOptions";
import { QUERY_KEY__EVENT } from "@/const/queryKeys";

interface DefaultValues {
  name: string;
}

interface Props {
  mailingToEdit?: BaseMailing;
  editId?: string;
}

const ERROR_API_MESSAGE = "Что-то пошло не так :(";

const Form: FC<Props> = ({ mailingToEdit, editId }) => {
  const defaultValues: DefaultValues = {
    name: mailingToEdit?.name || "",
  };

  const navigate = useNavigate();

  const handleCreateClick = async (data: DefaultValues) => {
    const { name } = data;

    if (name.length < 1) {
      alert("Длина должна быть более 1 символа");
      return;
    }

    const sended = await createMailing(name);

    if (sended) {
      alert("Рассылка успешно создана!");

      navigate(ROUTE__MAILING);
    } else {
      alert(ERROR_API_MESSAGE);
    }
  };

  const handleEditClick = async (data: DefaultValues) => {
    if (!editId) {
      return;
    }

    const { name } = data;

    if (name.length < 1) {
      alert("Длина должна быть более 1 символа");
      return;
    }

    const sended = await editMailing(editId, name);

    if (sended) {
      alert("Успешно изменил данные!");

      // После сохранения заявки на бэкенде, нужно очистить её в кэше.
      // Иначе в кэше останется старая заявка и при следующем открытии она сразу возьмётся из кэша
      // и попадёт в состояние форме. react-query перезапросит данные на бэкенде и обновит кэш, но
      // в состоянии формы останутся прежние значения (старые).
      await queryClient.invalidateQueries(QUERY_KEY__EVENT);

      navigate(ROUTE__MAILING);
    } else {
      alert(ERROR_API_MESSAGE);
    }
  };

  const handleCancelClick = () => {
    navigate(ROUTE__MAILING);
  };

  return (
    <>
      <FormContainer
        defaultValues={defaultValues}
        mode="onTouched"
        onSuccess={(data) =>
          mailingToEdit ? handleEditClick(data) : handleCreateClick(data)
        }
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={3}
        >
          <TextFieldElement
            variant="outlined"
            name="name"
            label="Название проекта"
            required
          />

          <Stack direction="row" spacing={2}>
            <Button variant="contained" type="submit">
              {mailingToEdit ? "Сохранить" : "Создать"}
            </Button>

            {editId ? (
              <Button variant="outlined" onClick={handleCancelClick}>
                Отмена
              </Button>
            ) : null}
          </Stack>
        </Stack>
      </FormContainer>
    </>
  );
};

export default Form;
