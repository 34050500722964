import { Button, Stack } from "@mui/material";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { useNavigate } from "react-router-dom";
import { ROUTE__EVENTS } from "../../../../../../const/routes";
import { createEvent, editEvent } from "../../../../api";
import { BaseEvent } from "../../../../api/types";
import { FC } from "react";
import { queryClient } from "@/reactQueryOptions";
import { QUERY_KEY__EVENT } from "@/const/queryKeys";

interface DefaultValues {
  name: string;
}

interface Props {
  eventToEdit?: BaseEvent;
  editEventId?: string;
}

const Form: FC<Props> = ({ eventToEdit, editEventId }) => {
  const defaultValues: DefaultValues = {
    name: eventToEdit?.name || "",
  };

  const navigate = useNavigate();

  const handleCreateClick = async (data: DefaultValues) => {
    const { name } = data;

    if (name.length < 1) {
      alert("Длина должна быть более 1 символа");
      return;
    }

    const sended = await createEvent(name);

    if (sended) {
      alert("Не забудь активировать event!");

      navigate(ROUTE__EVENTS);
    } else {
      alert("Что-то пошло не так, event не создался");
    }
  };

  const handleEditClick = async (data: DefaultValues) => {
    if (!editEventId) {
      return;
    }

    const { name } = data;

    if (name.length < 1) {
      alert("Длина должна быть более 1 символа");
      return;
    }

    const sended = await editEvent(editEventId, name);

    if (sended) {
      alert("Успешно изменил данные!");
      await queryClient.invalidateQueries(QUERY_KEY__EVENT);

      navigate(ROUTE__EVENTS);
    } else {
      alert("Что-то пошло не так, event не создался");
    }
  };

  const handleCancelClick = () => {
    navigate(ROUTE__EVENTS);
  };

  return (
    <>
      <FormContainer
        defaultValues={defaultValues}
        mode="onTouched"
        onSuccess={(data) =>
          eventToEdit ? handleEditClick(data) : handleCreateClick(data)
        }
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={3}
        >
          <TextFieldElement
            variant="outlined"
            name="name"
            label="Название проекта"
            required
          />

          <Stack direction="row" spacing={2}>
            <Button variant="contained" type="submit">
              {eventToEdit ? "Сохранить" : "Создать"}
            </Button>

            {editEventId ? (
              <Button variant="outlined" onClick={handleCancelClick}>
                Отмена
              </Button>
            ) : null}
          </Stack>
        </Stack>
      </FormContainer>
    </>
  );
};

export default Form;
