import { Box, Container } from "@mui/material";
import Form from "./Form";
import Header from "@/components/Header";

const CreateMailing = () => {
  return (
    <Container maxWidth="md">
      <Box pt={3}>
        <Header title="Создание рассылки" />
      </Box>

      {/* form */}
      <Box pt={3}>
        <Form />
      </Box>
    </Container>
  );
};

export default CreateMailing;
