import { axi } from "../../../api/axiosInstance";
import {
  ACTIVATE_EVENT_API,
  CREATE_EVENT_API,
  DISABLE_EVENT_API,
  EDIT_EVENT_API,
  EVENT_FOR_EDIT_API,
  EVENTS_API,
} from "./const";
import { mapperEventFromBackend, mapperEventsFromBackend } from "./mapper";
import {
  BaseEvent,
  EditEventWithUsers,
  EventBackend,
  EventFrontend,
  GetEventsInputParams,
} from "./types";

export const getEvent = async (
  id: number
): Promise<EditEventWithUsers | null> => {
  try {
    const resp = await axi.get<EventBackend>(`${EVENT_FOR_EDIT_API}`, {
      params: {
        id,
      },
    });
    const backendData = resp.data;

    return mapperEventFromBackend(backendData);
  } catch (error) {
    console.log("getEvent error: ", error);

    return null;
  }
};

export const getEvents = async ({
  page = 1,
}: GetEventsInputParams): Promise<EventFrontend> => {
  let resp;

  try {
    resp = await axi.get(EVENTS_API, {
      params: {
        page,
        itemsPerPage: 10,
      },
    });
  } catch (error) {
    console.log(error);
  }

  return mapperEventsFromBackend(resp?.data);
};

export const createEvent = async (name: string) => {
  let resp;

  try {
    resp = await axi.post(CREATE_EVENT_API, {
      name,
    });
  } catch (error) {
    console.log("createEvent error: ", error);
  }

  return resp;
};

export const editEvent = async (id: string, name: string): Promise<boolean> => {
  let resp;

  try {
    resp = await axi.post(EDIT_EVENT_API, {
      id,
      name,
    });
  } catch (error) {
    console.log("createEvent error: ", error);
  }

  return resp?.data ? resp.data : false;
};

export const activateEvent = async (eventId: number): Promise<boolean> => {
  let resp = false;

  try {
    resp = await axi.post(ACTIVATE_EVENT_API, {
      eventId,
    });
  } catch (error) {
    console.log(error);
  }

  return resp;
};

export const disableEvent = async (eventId: number): Promise<boolean> => {
  let resp = false;

  try {
    resp = await axi.post(DISABLE_EVENT_API, {
      eventId,
    });
  } catch (error) {
    console.log(error);
  }

  return resp;
};
